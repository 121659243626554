#jokes {
  display: grid;
  padding: 0 1rem 3rem;
}

/* RANDOM JOKES */

#mobile-toolbar {
  position: sticky;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: auto;
  padding: 1rem;
  background: var(--bg-100);
}

.filter-wrap {
  position: absolute;
  bottom: 94px;
  right: 16px;
  padding: 0.5rem 0.5rem 0.25rem;
  background: var(--bg-100);
  border: 2px solid var(--primary-200);
  border-radius: 1rem;
}

#filter-select {
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background: var(--bg-100);
}

#filter-select:active,
#filter-select:hover {
  outline: none;
}

#get-joke-btn {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  /*max-width: 400px;*/
  padding: 1.25rem 0 1rem;
  background: var(--primary-100);
  border: 4px solid var(--bg-100);
  border-radius: 1rem;
}

#get-joke-btn:hover,
#get-joke-btn:active {
  border: 4px solid var(--bg-200);
}


/* JOKE LIST */

.joke-list {
  display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));*/
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: 1.5rem;
  padding: 1rem 0;
}


/* JOKE ITEM */

.joke-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem;
  background: var(--bg-200);
  border-radius: 1rem;
  border-left: 2px solid var(--primary-100);
  transition: .15s ease-in;
}

.joke-text {
  font-size: 1.25rem;
  line-height: 1.3;
  margin: 0 0 1.25rem;
}

.joke-bar {
  display: flex;
}

.joke-category {
  font-weight: 600;
  padding: 0.5rem 1rem 0.25rem;
  margin-right: auto;
  border-radius: 0.5rem;
  color: var(--primary-200);
  background: var(--bg-100);
}

.joke-btn {
  padding: 0.25rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
}

.joke-btn:hover,
.joke-btn:active {
  background: var(--bg-100);
}

.joke-btn svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: var(--primary-200);
}

.joke-btn .is-sharing,
.joke-btn .is-fav,
.joke-btn .is-playing {
  fill: var(--primary-200);
}

@media screen and (min-width: 768px) {

  .joke-list {
    grid-gap: 2rem;
  }

  .joke-item {
    padding: 1.5rem;
  }

  .joke-text {
    font-size: 1.5rem;
    margin: 0 0 1.5rem;
  }

  .joke-category {
    font-size: 1.25rem;
  }

  .joke-btn svg {
    width: 30px;
    height: 30px;
  }

}

/* LOAD MORE */

#load-more {
  min-width: 350px;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 0 0.75rem;
  margin: 0.5rem 1rem;
  border-radius: 1rem;
  justify-self: center;
  background: var(--primary-100);
}

/* JOKE POP-IN ANIMATION */

.pop-in {
  opacity: 0;
  transform: scale(0.75);
  animation: openspace 150ms ease forwards,
  restored-item-animation 150ms 150ms cubic-bezier(0, .8, .32, 1.07) forwards;
}

@keyframes openspace {
  to {
    height: auto;
  }
}

@keyframes restored-item-animation {

  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  75% {
    opacity: 0.75;
    transform: scale(1.005);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }

}
